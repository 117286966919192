<template>
  <div class="bottom">
    <div class="bottombac">
      <div class="QRcode">
        <ul>
          <li v-for="(item,index) in bottomArry" :key="index">
            <img :src="item.srcArry" />
            <p>{{item.text}}</p>
          </li>
        </ul>
      </div>
      <!-- <div class="xian"><p></p><p></p></div> -->
      <div class="detailPage">
        <ul>
          <li v-for="(ite,index) in detailArry" :key="index">
            <img :src="ite.srcArry" />
            <div class="p">
              <p>{{ite.call}}</p>
              <p>{{ite.text}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="conpy">
      <!-- Copyright © 2021 版权所有：深圳市钱海屯物联网科技有限公司 备案号:粤ICP备2021038166号 -->
      <p>Copyright &copy;2021 版权所有：深圳市钱海屯物联网科技有限公司</p>
      <p><a style="color: #959b9f" target="_blank" href="https://beian.miit.gov.cn/">备案号:粤ICP备2021038166号</a></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      bottomArry: [
        {
          srcArry: require("../assets/img/wx_gfkf.png"),
          text: "官方客服"
        },
        {
          srcArry: require("../assets/img/wx_xcx.png"),
          text: "小程序二维码"
        }
      ],
      detailArry: [
        {
          srcArry: require("@/assets/img/phone-in-talk-32px.png"),
          call: "4000317888",
          text: "咨询电话：周一至周五 9：00 — 6：00"
        },
        {
          srcArry: require("../assets/img/location-on-round-24px.png"),
          text: "公司地址：广东省深圳市南山区卓越前海壹号B座"
        },
        {
          srcArry: require("../assets/img/email-sharp-24px.png"),
          text: "合作邮箱 : account@qianhaitun.com"
        }
      ]
    };
  },
  methods: {
    btn() {
      this.ishow = !this.ishow;
    }
  }
};
</script>
<style lang="scss" scoped>
.bottom {
  width: 100%;
  // height: 325px;
  background: #43525b;
  // position:relative;
  bottom: 0;
  left: 0;
  .bottombac {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 34px 0px;
    margin: 0 auto;
  }
  .QRcode {
    flex: 1;
    padding-right: 110px;
    border-right: 1px soli;
    border-color: rgba(151, 151, 151, 0.2);
    border-width: 0.1px;
    ul {
      width: 100%;
      display: flex;
      list-style: none;
      justify-content: flex-end;
      align-items: center;
      padding: 7px 0px;
      > li {
        display: flex;
        margin-left: 64px;
        flex-direction: column;
        align-items: center;
        padding: 0px 0px;
        color: #959b9f;
        p {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #cfcfcf;
          line-height: 20px;
          margin-top: 10px;
        }
      }
    }
  }
  .detailPage {
    flex: 1;
    align-items: center;
    border-left: 1px solid;
    border-color: rgba(151, 151, 151, 0.2);
    padding-left: 100px;
    ul {
    
      list-style: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: max-content;
      > li {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        color: #959b9f;
        padding: 0 10px;
        img {
          margin-right: 10px;
        }
        .p {
          display: flex;
          flex-direction: column;
          text-indent: 10px;
          font-size: 14px;
          font-weight: 500;
          color: #cfcfcf;
        }
        .p>p:nth-of-type(1){
          color: #238AF2;
          font-size: 24px;
        }
      };
      li:nth-of-type(3){
        margin-bottom: 0px;
      };
    }
  }
  .conpy {
    
    border-top: 0.2px solid #979797;
    border-color: rgba(151, 151, 151, 0.2);
    border-width: 0.1px;
    width: 100%;
    text-align: center;
    color: #959b9f;
    padding: 19px 0px;
    > p {
      // margin-bottom: 10px;
      // line-height: 3px;
      font-size: 12px;
      
    }
  }
}

@media screen and (max-width: 991px) {
  .bottom{
     width: 100%;
  background: #43525b;
  // position:relative;
  bottom: 0;
  left: 0;
  .bottombac {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    margin: 0 auto;
  }
  .QRcode {
    flex: 1;
    padding-right: 0px;
    border-right: 0px soli;
    border-color: rgba(151, 151, 151, 0.2);
    border-width: 0.1px;
    ul {
      width: 100%;
      display: flex;
      list-style: none;
      // justify-content: flex-end;
      align-items: center;
      padding: 7px 0px;
      > li {
        display: flex;
        margin: 5px;
        flex-direction: column;
        align-items: center;
        padding: 0px 0px;
        color: #959b9f;
        img {
          width: 216px;
        }
        p {
          height: 20px;
          font-size: 28px;
          font-weight: 400;
          color: #cfcfcf;
          line-height: 20px;
          margin-top: 10px;
        }
      }
    }
  }
  .detailPage {
    flex: 1;
    align-items: center;
    border-left: 0px solid;
    // border-color: rgba(151, 151, 151, 0.2);
    padding-left: 0px;
    margin-top: 30px;
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: max-content;
      > li {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        color: #959b9f;
        img {
          margin-right: 10px;
        }
        .p {
          display: flex;
          flex-direction: column;
          text-indent: 10px;
          font-size: 24px;
          font-weight: 500;
          color: #cfcfcf;
        }
        .p>p:nth-of-type(1){
          color: #238AF2;
          font-size: 24px;
        }
      };
      li:nth-of-type(3){
        margin-bottom: 0px;
      };
    }
  }
  .conpy {
    
    border-top: 0.2px solid #979797;
    border-color: rgba(151, 151, 151, 0.2);
    border-width: 0.1px;
    width: 100%;
    text-align: center;
    color: #959b9f;
    padding: 20px 0px 25px 0px;
    > p {
      // margin-bottom: 10px;
      // line-height: 3px;
      font-size: 18px;
      
    }
  }
  }
}
</style>