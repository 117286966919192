<template>
  <div class="home">
    <div class="head_img"></div>
    <div class="aboutQHT">
      <div class="left">
        <div>
          <img src="../assets/img/about1.png" alt />
        </div>
        <div>
          <img src="../assets/img/about2.png" alt />
        </div>
      </div>
      <div class="right">
        <div class="sekuai"></div>
        <div class="wenzhang">
          <h2 class="title">关于钱海屯</h2>
          <div class="text">
            <p>
              深圳市钱海屯物联网科技有限公司是天津洁盛环境卫生管理（集团）有限公司控股公司。
            </p>
            <p>
              天津洁盛环境卫生管理（集团）有限公司是大型产业链配套齐全的环保集团公司，控股公司拥有4家子公司，
              分别是天津洁胜环保工程有限公司、天津捷盛环卫服务有限公司、天津洁胜物业管理有限公司、深圳市钱海屯物联网科技有限公司。
              天津洁胜环保工程有限公司专门从事建筑垃圾粉碎，二灰制作路牙砖小气块砖制作等，是住建部在天津市建筑垃圾试点工程；
              天津捷盛环卫服务有限公司专门从事城乡环卫一体化服务；天津洁胜物业管理有限公司专门从事小区物业管理。
              深圳市钱海屯物联网科技有限公司目前位于世界的大湾区深圳前海，主要负责智慧环卫项目技术开发及推广。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="connectus">
      <div>
        <div class="left">
          <div class="sekuai"></div>
          <div class="wenzhang">
            <h2 class="title">联系我们</h2>
            <div class="text">
              <p>联系电话：4000317888</p>
              <p>公司地址：广东省深圳市南山区卓越前海壹号B座</p>
            </div>
          </div>
        </div>
        <div class="right">
          <Map></Map>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Map from "../components/Map";
import Home from "../views/Home";
import Bottom from "../components/Bottom";
export default {
  name: "Aboutwe",
  components: {
    Map,
    Home,
    Bottom,
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .head_img {
    height: 560px;
    width: 100%;
    background: url("../assets/img/bj-jiaru.png") no-repeat;
    background-size: cover;
    background-position: center;
  }
  .aboutQHT {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    margin-top: 44px;
    padding-bottom: 137px;
    .left {
      flex: 0 0 453px;
      div {
        width: 100%;
        height: 130px;
        vertical-align: middle;
        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
        }
      }
      div:nth-of-type(2) {
        margin-top: 16px;
      }
    }
    .right {
      flex: 1;
      margin-left: 147px;
      display: flex;
      .sekuai {
        flex: 0 0 14px;
        height: 67px;
        background: #238af2;
      }
      .wenzhang {
        margin-left: 24px;
        .title {
          // width: 140px;
          height: 33px;
          font-size: 24px;
          font-weight: 600;
          color: #000000;
          line-height: 33px;
        }
        .text {
          margin-top: 38px;
          width: 775px;
          // height: 188px;
          font-size: 14px;
          font-weight: 400;
          color: #535354;
          line-height: 24px;
          letter-spacing: 2px;
        }
      }
    }
  }
  .connectus {
    width: 100%;
    // background-color: #d8d8d8;
    background-color: #f9f9fa;
    border-bottom: 1px solid #979797;
    padding: 56px 0px 45px 0px;
    > div {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        display: flex;
        .sekuai {
          flex: 0 0 14px;
          height: 67px;
          background: #238af2;
        }
        .wenzhang {
          margin-left: 24px;
          .title {
            // width: 140px;
            height: 33px;
            font-size: 24px;
            font-weight: 600;
            color: #000000;
            line-height: 33px;
          }
          .text {
            margin-top: 38px;
            width: 489px;
            height: 216px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #535354;
            line-height: 24px;
            letter-spacing: 2px;
          }
        }
      }
      .right {
        flex: 1 1 814px;
        height: 80%;
        margin-left: 113px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .home {
    .head_img {
      width: 100%;
      background-size: contain;
      background-size: 100% 100%;
      height: 240px;
    };
    .aboutQHT {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 44px;
      padding-bottom: 0px;
      .left {
        flex: 1;
        width: 90%;
        display: flex;
        align-items: center;
        gap: 20px;
        div {
          width: 100%;
          height: auto;
          vertical-align: middle;
          img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
        div:nth-of-type(2) {
          margin-top: 0px;
        }
      }
      .right {
        width: 90%;
        margin: 0% auto;
        padding: 20px 0px;
        display: flex;
        .sekuai {
          flex: 0 0 14px;
          height: 67px;
          background: #238af2;
        }
        .wenzhang {
          margin-left: 20px;
          .title {
            width: 100%;
            font-size: 32px;
            font-weight: 550;
            color: #000000;
            line-height: 33px;
          }
          .text {
            margin-top: 38px;
            width: 100%;
            font-size: 26px;
            font-weight: 400;
            color: #535354;
            line-height: 200%;
            letter-spacing: 2px;
          }
        }
      }
    };
    .connectus {
      width: 100%;
      background-color: #f9f9fa;
      border-bottom: 1px solid #979797;
      padding: 10px 0px 10px 0px;
      > div {
        width: 100%;
        margin: 0 auto;
        display: flex;
        padding: 0%;
        flex-direction: column;
        align-items: center;
        .left {
          width: 90%;
          flex: none;
          display: flex;
          .sekuai {
            flex: 0 0 14px;
            height: 67px;
            background: #238af2;
          }
          .wenzhang {
            margin-left: 24px;
            .title {
              font-size: 32px;
              width: 100%;
              font-weight: 550;
              color: #000000;
              line-height: 33px;
            }
            .text {
              font-size: 26px;
              margin-top: 38px;
              width: 529px;
              height: 216px;
              font-weight: 400;
              color: #535354;
              line-height: 150%;
              letter-spacing: 2px;
            }
          }
        }
        .right {
          flex: none;
          width: 100%;
          margin-left: 0%;
          contain: paint;
        }
      }
    }
  }
}
</style>