<template>
  <div class="home">
    <keep-alive>
      <!-- <Home></Home> -->
    </keep-alive>
    <div class="header">
      <div>
        <h1>智能硬件产品终端</h1>
        <p>为小区合理匹配智能垃圾投递柜， 解决用户垃圾分类投递困扰。</p>
        <div>
          <img src="../assets/img/banner_more.svg" alt />
        </div>
      </div>
    </div>
    <!-- ---------------------------------------------- -->
    <div class="session">
      <div class="row_one">
        <div class="img">
          <img src="../assets/img/znghs.png" alt />
        </div>
        <div class="shuoming">
          <div>
            <h2>智能回收柜</h2>
            <p>
              为小区投放四分类智能垃圾箱，
              <br />有偿回收可回收物品。
            </p>
            <p>-用户可通过小程序查看环保金。</p>
          </div>
        </div>
      </div>
      <div class="row_two">
        <div class="shuoming">
          <div>
            <div>
              <h2>小程序-随时互动</h2>
              <p>
                用户按照智能端提示操作，
                <br />进行垃圾投放后，可获得积分。
              </p>
              <p>-积分可提现使用;</p>
              <p>-积分可兑换商品使用。</p>
            </div>
          </div>
        </div>
        <div class="img">
          <img src="../assets/img/xcx.png" alt />
        </div>
      </div>
      <div class="row_three">
        <div class="img">
          <img src="../assets/img/szyy.png" alt />
        </div>
        <div class="shuoming">
          <div>
            <h2>数据可视化运营平台</h2>
            <p>多维组件自由布局，<br>实时观测用户数据；</p>
            <p>-业务场景随时预置，<br>设备状态尽在掌握；</p>
            <p>-一键广告投递，<br>高曝光低成本，业务数据精准推动。</p>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Home from "../views/Home";
import Bottom from "../components/Bottom";
export default {
  name: "Joinwe",
  components: {
    Home,
    Bottom,
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .header {
    background: url("../assets/img/banner.png") no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 34.93333rem;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    > div {
      //   width: 75%;
      width: 1440px;
      margin: 0 auto;
      padding: 180px 0px 0px 0px;
      > h1 {
        margin: 30px 0px 0px 65px;
        // width: 608px;
        height: 70px;
        font-size: 70px;
        font-weight: 600;
        color: #333333;
        line-height: 70px;
        letter-spacing: 6px;
        color: #ffffff;
      }
      > p {
        width: 420px;
        height: 72px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 150%;
        letter-spacing: 4px;
        margin: 20px 0px 0px 65px;
      }
      > div {
        margin: 40px 0px 0px 65px;
        img {
          width: 112px;
          height: 40px;
          background: rgba(21, 126, 251, 0.1);
          box-shadow: 0px 4px 6px 0px rgba(21, 126, 251, 0.16);
          border-radius: 24px;
        }
      }
    }
  }
  .session {
    img {
      margin: 0px 20px;
    }
    .shuoming {
      margin: 0px 20px;
    }
    padding-bottom: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // padding: 70px 234px;
    .row_one,
    .row_three {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .img {
        flex: 6;
        text-align: right;
        margin-right: 20px;
        > img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .shuoming {
        height: 210px;
        flex: 5;
        padding-top: 55px;
        background: url("../assets/img/yuan.png") no-repeat;
        background-position: 0 0;
        background-size: 160px 160px;
        h2 {
          color: #000000;
          padding: 10px 0px;
          margin-left: 80px;
          letter-spacing: 8px;
        }
        p {
          color: #a5a5a5;
          font-size: 13px;
          margin-left: 80px;
          letter-spacing: 5px;
        }
        P:nth-of-type(1) {
          padding: 10px 0px 15px 0px;
        }
      }
    }
    .row_two {
      width: 100%;
      display: flex;
      // justify-content: space-around;
      align-items: center;
      .img {
        flex: 1.3;
        // text-align: left;
        > img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .shuoming {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        > div {
          width: 100%;
          height: 210px;
          background: url("../assets/img/yuan.png") no-repeat;
          background-position: 70% 0;
          background-size: 160px 160px;
          padding-top: 52px;
          > div {
            margin-left: 65%;
          }
          h2 {
            color: #000000;
            padding: 10px 0px;
            letter-spacing: 8px;
          }
          p {
            color: #a5a5a5;
            font-size: 13px;
            letter-spacing: 5px;
          }
          P:nth-of-type(1) {
            padding: 10px 0px 15px 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .home {
    width: 100%;
    padding: 0px;
    .header {
      padding: 0px;
      background: url("../assets/img/banner-m.png") no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      max-width: 100%;
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
      height: 340px;
      > div {
        //   width: 75%;
        width: auto;
        margin: 0 auto;
        padding-top: 80px;
        padding-left: 40px;
        > h1 {
          margin: 10px 0px 0px 0px;
          width: 100%;
          height: auto;
          font-size: 36px;
          font-weight: 550;
          color: #333333;
          letter-spacing: 2px;
          color: #ffffff;
        }
        > p {
          width: 50%;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 150%;
          letter-spacing: 1px;
          margin: 0px;
        }
        > div {
          margin: 0px;
          img {
            width: 102px;
          }
        }
      }
    }
    .session {
      img {
        margin: 0px 0px;
      }
      .shuoming {
        margin: 0px 0px;
      }
      padding-bottom: 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      // padding: 70px 234px;
      .row_one,
      .row_three {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          flex: 1;
          text-align: right;
          margin-right: 0px;
          > img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .shuoming {
          height: 210px;
          flex: 5;
          padding-top: 50px;
          background: url("../assets/img/yuan.png") no-repeat;
          background-position: 0 0;
          background-size: 160px 160px;
          width: auto;
          display: flex;
          justify-content: center;
          > div {
            padding-left: 70px;
          }
          h2 {
            color: #000000;
            padding: 10px 0px;
            margin-left: 0px;
            letter-spacing: 8px;
            font-size: 40px;
          }
          p {
            color: #a5a5a5;
            font-size: 20px;
            margin-left: 0px;
            letter-spacing: 5px;
          }
          P:nth-of-type(1) {
            padding: 10px 0px 15px 0px;
            font-size: 24px;
          }
        }
      }
      .row_two {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        .img {
          width: 100%;
          flex: 1;
          text-align: center;
          > img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .shuoming {
          // display: none;
          width: auto;
          display: flex;
          justify-content: center;
          // padding-left: 26%;
          > div {
            width: auto;
            height: 210px;
            background: url("../assets/img/yuan.png") no-repeat;
            background-position: 0px 0;
            background-size: 160px 160px;
            padding-top: 52px;
            margin-left: 30px;
            display: flex;
            justify-content: center;
            > div {
              margin-left: 70px;
            }
            h2 {
              color: #000000;
              padding: 10px 0px;
              letter-spacing: 8px;
              font-size: 40px;
            }
            p {
              color: #a5a5a5;
              font-size: 20px;
              letter-spacing: 5px;
            }
            P:nth-of-type(1) {
              padding: 10px 0px 15px 0px;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>