<template>
  <div class="home">
    <div class="datu">
      <div class="bg_img">
        <div class="img_text">
          <p>物联网+智能回收</p>
          <p>提供全链路智能回收解决方案</p>
        </div>
        <div class="datu_items">
          <ul>
            <li>
              <div class="tupian">
                <img src="../assets/img/toufangcishu.png" alt />
              </div>
              <div class="tongji">
                <p>400W+</p>
                <p>累计投递（次）</p>
              </div>
            </li>
            <li>
              <div class="tupian">
                <img src="../assets/img/xianshangyonghu.png" alt />
              </div>

              <div class="tongji">
                <p>4W+</p>
                <p>线上用户（人）</p>
              </div>
            </li>
            <li>
              <div class="tupian">
                <img src="../assets/img/shebei.png" alt />
              </div>

              <div class="tongji">
                <p>500+</p>
                <p>在线设备（台）</p>
              </div>
            </li>
            <li>
              <div class="tupian">
                <img src="../assets/img/yuejuntoufang.png" alt />
              </div>

              <div class="tongji">
                <p>120+</p>
                <p>月均回收（吨）</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="img"> <img src="../assets/img/bj.png" width="100%" :height="windowHeight+'px'" /></div> -->

      <!-- <img src="../assets/img/1920.png" width="100%" /> -->
    </div>
    <!-- ==================================== -->

    <div class="programa">
      <h1 class="title_one">智能回收终端</h1>
      <div id class="room zhongduanroom">
        <div id class="recycling_bins">
          <img src="../assets/img/recycling_bins.png" />
          <!-- <img src="../assets/img/huishougui.jpg" alt=""> -->
          <!-- <img src="../assets/img/huishougui526.jpg" alt /> -->
        </div>
        <div class="introduce">
          <ul class="introduce_item">
            <li>
              <p>
                <img src="../assets/img/icon-sun.png" width="49" height="48" />
              </p>
              <div id class="introduce_item_text">
                <p>太阳能供电</p>
                <p>太阳能光伏板发电，节能环保</p>
              </div>
            </li>
            <li>
              <p>
                <img
                  src="../assets/img/icon-dingwei.png"
                  width="49"
                  height="48"
                />
              </p>
              <div id class="introduce_item_text">
                <p>定位拍摄</p>
                <p>GPS定位，摄像头监控，后台监管</p>
              </div>
            </li>
            <li>
              <p>
                <img
                  src="../assets/img/icon-toudi.png"
                  width="49"
                  height="48"
                />
              </p>
              <div id class="introduce_item_text">
                <p>投递门</p>
                <p>智能感应开门，实时称重</p>
              </div>
            </li>
            <li>
              <p>
                <img
                  src="../assets/img/icon-manzai.png"
                  width="49"
                  height="48"
                />
              </p>
              <div id class="introduce_item_text">
                <p>满载提醒、烟雾报警</p>
                <p>后台大数据检测，实时调度</p>
              </div>
            </li>
            <li>
              <p>
                <img src="../assets/img/icon-7.png" width="49" height="48" />
              </p>
              <div id class="introduce_item_text">
                <p>7寸高清智能显示屏</p>
                <p>编辑操作，智能语音提示</p>
              </div>
            </li>
            <li>
              <p>
                <img
                  src="../assets/img/icon-huishou.png"
                  width="49"
                  height="48"
                />
              </p>
              <div id class="introduce_item_text">
                <p>可回收物有偿回收</p>
                <p>微信扫码、老人刷卡</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- ====================================== -->
    <div class="programa2">
      <h1 class="title_one">多平台实时互动智能回收</h1>
      <p class="programa2_text">
        作为公司主打产品，小海豚智能回收采取“环保+互联网”有偿回收的方式,与社区居民进行垃圾分类互动。居民依据提示操作，可以从可回收垃圾中获取一定的报酬，可提现也可用于商品兑换。
      </p>
      <div class="programa2_items">
        <div>
          <p>智能终端</p>
          <p>社区源头终端回收</p>
        </div>
        <div>
          <p>小程序</p>
          <p>一键开门，互动提现</p>
        </div>
        <div>
          <p>数字化运营平台</p>
          <p>实时查看，资源数据化</p>
        </div>
      </div>
    </div>

    <!-- ============================================ -->
    <div id class="programa3">
      <h1 class="title_one">全链路智能回收</h1>
      <div id class="programa3_liucheng">
        <p>收集</p>
        <img src="../assets/img/jiantou.png" />
        <p>分拣</p>
        <img src="../assets/img/jiantou.png" />
        <p>再生</p>
      </div>
      <div id class="programa3_items">
        <div id class="programa3_item1">
          <div class="img_home">
            <img src="../assets/img/yuantou.png" />
          </div>
          <div>
            源头回收
            <p class="xhx"></p>
          </div>
          <p>通过社区，市场等途径宣传垃圾分类，可回收物有偿回收</p>
        </div>
        <div id class="programa3_item1">
          <div class="img_home">
            <img src="../assets/img/zhuancheyunshu.png" />
          </div>
          <div>
            专车运输
            <p class="xhx"></p>
          </div>
          <p>各类型专业垃圾运输车，依据垃圾类型进行分类运输。</p>
        </div>
        <div id class="programa3_item1">
          <div class="img_home">
            <img src="../assets/img/fenjian.png" />
          </div>
          <div>
            分拣打包
            <p class="xhx"></p>
          </div>
          <p>分拣中心再次审查分拣，打包装箱后分类运输，直供工厂。</p>
        </div>
        <div id class="programa3_item1">
          <div class="img_home">
            <img src="../assets/img/zaishengliyong.png" />
          </div>
          <div>
            再生利用
            <p class="xhx"></p>
          </div>
          <p>多类型下游工厂，进行垃圾处理后资源再生为商品，二次出售。</p>
        </div>
      </div>
    </div>

    <!-- ============================================== -->

    <div id class="programa4">
      <h1 class="title_one">合作案例</h1>
      <div>
        <div id class="programa4_items">
          <div id class="programa4_items_left">
            <div id class="sekuai"></div>
            <div id class="programa4_room">
              <p class="programa4_items_left_title">钱海屯智慧回收解决方案</p>
              <ul>
                <li>-提供全链路智慧解决方案</li>
                <li>-智能投放，app智能提示</li>
                <li>-提供全链路数字化运营解决方案</li>
                <li>-小程序互动，积分体系有偿回收</li>
                <li>-巴哈那生活平台，打通社区1公里生活圈</li>
              </ul>
            </div>
          </div>
          <div id class="programa4_items_right">
            <!-- <el-carousel :interval="5000" height="384px" arrow="always" class="el_lunbo">
              <el-carousel-item v-for="(item,index) in hezuolunboimg" :key="index">
                 <img :src="item" alt="" />
              </el-carousel-item>
            </el-carousel> -->
            <el-carousel :interval="5000" arrow="always" class="el_lunbo">
              <el-carousel-item v-for="(item,index) in hezuolunboimg" :key="index">
                 <img :src="item" alt="" style="height:300px"/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        
        <!-- <div class="fenye">
          <div>
            <span id="img_left" @click="left"
              ><img src="../assets/img/arrow-left-12px.png" alt=""
            /></span>
            <span>1/6</span>
            <span id="img_right" @click="right"
              ><img src="../assets/img/arrow-right-12px.png" alt=""
            /></span>
          </div>
        </div> -->
      </div>
    </div>

    <!-- ================================================= -->

    <!-- <News></News> -->
    <!-- <div class="joinwe_H5">
      <h2 class="callwe">联系我们</h2>
      <p class="tishi">如果您有建议或任何疑问，请联系我们</p>
      <div class="dianhua">
        <span><img src="../assets/img/phone-in-talk-32px.png" alt="" /></span>
        <div>
          <p>400-1234-678</p>
          <p>联系电话 周一至周五 9:00 ——6:00</p>
        </div>
      </div>
      <div class="dizhi">
        <span
          ><img src="../assets/img/location-on-round-24px.png" alt=""
        /></span>
        <p>联系地址 ：广东省深圳市前海卓越壹号B座302B</p>
      </div>
      <div class="email">
        <span><img src="../assets/img/email-sharp-24px.png" alt="" /></span>
        <p>联系邮箱 : 12guanfangyouxiang@QHT.com</p>
      </div>
      <div class="from_H5">
        <div>
          <input type="text" name placeholder="姓名" id v-model="name" />
        </div>
        <div>
          <input type="text" name placeholder="电话" id v-model="dianhua" />
        </div>
        <div>
          <input type="text" name placeholder="公司" id v-model="gongsi" />
        </div>
        <div>
          <textarea
            rows
            cols
            value
            placeholder="请输入留言内容..."
            v-model="liuyan"
          >
5454545554</textarea
          >
        </div>
        <div class="yanzhengma">
          <input type="text" name placeholder="验证码" id v-model="yzm" />
          <div><img :src="img" /></div>
        </div>
      </div>
    </div>
    <div class="foot">
      <ul>
        <li>首页</li>
        <li>产品介绍</li>
        <li>代理加盟</li>
        <li>关于我们</li>
      </ul>
      <div>
        <p>Copyright © 2020 版权所有：深圳市智洁方成科技有限公司</p>
        <p>备案号:粤ICP备20011151号</p>
      </div>
    </div> -->
    <div class="bottom"><Bottom></Bottom></div>
  </div>
</template>

<script>
import Home from "../views/Home";
// import News from "../views/News";
import Bottom from "../components/Bottom";
import { Carousel, CarouselItem } from "element-ui";
// import Lunbo from "../components/lunbotu";
// import Swiper from 'swiper';
// import 'swiper/css/swiper.min.css';
export default {
  name: "Shouye",
  components: {
    // News,
    Home,
    Bottom,
  },
  data() {
    return {
      // windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      // windowHeight: document.documentElement.clientHeight //实时屏幕高度
      styleObject: "",
      name: "",
      dianhua: "",
      gongsi: "",
      liuyan: "",
      yzm: "",
      img: "",
      hezuolunboimg: [
        require("../assets/img/hezuolunbo1.png"),
        require("../assets/img/hezuolunbo2.png"),
        require("../assets/img/hezuolunbo3.png"),
        require("../assets/img/hezuolunbo4.png"),
        require("../assets/img/hezuolunbo5.png"),
        // require("../assets/img/hezuolunbo6.png"),
      ],
    };
  },
  created() {
    // 获取验证吗
    this.$axios({
      methods: "GET",
      url: "/contactus/getcode",
    })
      .then((result) => {
        // console.log(result.data);
        this.img = "data:image/png;base64," + result.data.data;
      })
      .catch((err) => {
        // console.log(err);
      });
  },
  methods: {
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log("slide change");
    },
  },
};
</script>


<style lang="scss" scoped>
li {
  list-style: none;
}

  // .el-carousel__item h3 {
  //   color: #475669;
  //   font-size: 18px;
  //   opacity: 0.75;
  //   line-height: 300px;
  //   margin: 0;
  // }
  
  // .el-carousel__item:nth-child(2n) {
  //   background-color: #99a9bf;
  // }
  
  // .el-carousel__item:nth-child(2n+1) {
  //   background-color: #d3dce6;
  // }
  // .el-carousel__item{
  //   width: auto;
  // }

.home {
  width: 100%;
  .datu {
    width: 100%;
    .bg_img {
      background: url("../assets/img/banner2x.png") no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 1024px;
      //   max-width: 100%;
      //   max-height: 100%;
      //   display: block;
      //   margin: auto;
      .img_text {
        // position: absolute;
        // width: 100%;
        // bottom: 43%;
        > p:nth-of-type(1) {
          text-align: center;
          color: #ffffff;
          font-size: 68px;
          letter-spacing: 10px;
          padding-top: 400px;
          height: 95px;
        }
        > p:nth-of-type(2) {
          // width: 518px;
          text-align: center;
          color: #ffffff;
          font-size: 24px;
          margin-top: 5px;
        }
      }
      .datu_items {
        width: 1440px;
        height: 150px;
        margin: 0 auto;
        margin-top: 279px;
        > ul {
          padding: 10px 0px;
          background-color: #ffffff;
          display: flex;
          flex-direction: row;
          align-items: center;
          > li {
            flex: 1 1 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            .tupian {
              flex: 0 0 88px;
              width: 88px;
              height: 88px;
              margin-right: 5px;
              > img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: auto;
              }
            }
            .tongji {
              flex: 0 0 150px;
              p:nth-of-type(1) {
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #343434;
              }
              p:nth-of-type(2) {
                font-size: 12px;
                font-weight: 600;
                color: #666666;
              }
            }
          }
        }
      }
    }
  }
  .programa {
    // width: 100%;
    margin-top: 76px;
    h1 {
      width: 100%;
      height: 37px;
      line-height: 37px;
      font-size: 26px;
      text-align: center;
      font-weight: 550;
      color: #000000;
    }
    .zhongduanroom {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 36px;
      .recycling_bins {
        flex: 0 0 1051px;
        height: 624px;
        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
        }
      }
      .introduce {
        flex: 0 0 360px;
        margin-left: 25px;
        .introduce_item > li {
          width: 100%;
          height: 50px;
          display: flex;
          padding: 10px 0px;
          margin: 10px 0px;
          align-items: center;
          > p {
            flex: 0 0 49px;
            height: 48px;
            img {
              max-width: 100%;
              max-height: 100%;
              display: block;
              margin: auto;
            }
          }
          .introduce_item_text {
            margin-left: 30px;
            flex: 1 1 400px;
            > p:nth-of-type(1) {
              height: 24px;
              font-size: 22px;
              font-weight: 600;
              color: #333333;
              letter-spacing: 3px;
            }
            > p:nth-of-type(2) {
              height: 14px;
              line-height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: #666666a6;
              letter-spacing: 2px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .programa2 {
    background: url(../assets/img/jx.png) no-repeat;
    background-size: auto;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 460px;
    margin-top: 72px;
    padding: 68px 0px 0px 0px;
    h1 {
      width: 100%;
      height: 37px;
      line-height: 37px;
      font-size: 26px;
      text-align: center;
      font-weight: 550;
      color: #000000;
    }
    .programa2_text {
      line-height: 22px;
      width: 705px;
      height: 44px;
      margin: 0 auto;
      margin-top: 31px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #989898;
    }
    .programa2_items {
      width: 1440px;
      height: 150px;
      padding: 25px 0px 15px 0px;
      margin: 0 auto;
      margin-top: 45px;
      background: #ffffff;
      box-shadow: 0px 0px 18px 2px rgb(203 230 239 / 29%);
      display: flex;
      justify-content: space-around;
      align-items: center;
      > div > p:nth-of-type(1) {
        font-size: 32px;
        font-weight: bold;
        color: #000000;
      }
      > div > p:nth-of-type(2) {
        color: #666666a6;
        font-size: 12px;
        margin-top: 18px;
      }
    }
  }
  .programa3 {
    margin-top: 76px;
    h1 {
      width: 100%;
      height: 37px;
      line-height: 37px;
      font-size: 26px;
      text-align: center;
      font-weight: 550;
      color: #000000;
    }
    .programa3_liucheng {
      width: 747px;
      margin: 0 auto;
      margin-top: 44px;
      display: flex;
      align-items: center;
      > p {
        flex: 0 0 84px;
        text-align: center;
        font-weight: 500;
        color: #000000;
        font-size: 22px;
        width: 44px;
        height: 30px;
        line-height: 30px;
      }
      > img {
        flex: 1 1 auto;
      }
    }
    .programa3_items {
      // width: 75%;
      width: 1440px;
      margin: 0 auto;
      margin-top: 24px;
      padding-bottom: 60px;
      display: flex;
      flex-direction: row;
      > div {
        // width: 25%;
        width: 322px;
        height: 380px;
        background: #ffffff;
        border-radius: 4px;
        padding: 0px 0px;
        margin: 0px 20px;
        > .img_home {
          width: 120px;
          height: 120px;
          margin-top: 79px;
          margin-left: 31px;
          img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: auto;
          }
        }
        > div:nth-of-type(2) {
          margin-left: 31px;
          margin-top: 30px;
          font-weight: 600;
          color: #000000;
          width: 191px;
          height: 33px;
          font-size: 24px;
          > p {
            width: 55%;
            height: 6px;
            background-color: #157EFB;
            margin-top: -8px;
            display: none;
          }
        }
        > p {
          margin-left: 31px;
          margin-top: 15px;
          padding: 0px 0px;
          width: 271px;
          height: 50px;
          font-size: 18px;
          line-height: 25px;
          color: #666666a6;
        }
      }
      > div:hover {
        box-shadow: 0px 0px 18px 2px rgba(203, 230, 239, 0.49);
        .xhx {
          display: block;
        }
      }
    }
  }
  .programa4 {
    // display: flex;
    width: 100%;
    padding: 64px 0px 80px 0px;
    background-color: rgba(245, 245, 245, 0.3);
    > div {
      width: 1440px;
      margin: 0% auto;
      // display: flex;
      // justify-content: flex-end;
      max-width: 1440px;
      width: 75%;
    }
    h1 {
      width: 100%;
      // height: 37px;
      // line-height: 37px;
      font-size: 26px;
      text-align: center;
      font-weight: 550;
      color: #000000;
    }
    .programa4_items {
      // margin-left: 254px;
      display: flex;
      flex-direction: row;
      margin-top: 56px;
      .programa4_items_left {
        // flex: 1 1 624px;
        flex: 1;
        display: flex;
        border-bottom: 1px solid #979797;
        border-bottom-color: rgba(197, 185, 185, 0.2);
        .sekuai {
          background-color: #238af2;
          width: 14px;
          height: 67px;
          margin-top: 5px;
          margin-right: 20px;
        }
        .programa4_room > ul {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 10%;
          > li {
            padding: 5px 0px;
            list-style: none;
          }
        }
        .programa4_room > .programa4_items_left_title {
          width: 288px;
          height: 33px;
          font-size: 24px;
          font-weight: 600;
          color: #000000;
          line-height: 33px;
        }
      }
      .programa4_items_right {
        flex: 1.3;
        margin-left: 26px;
        img{
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    // .fenye {
    //   width: 120px;
    //   margin: 0 auto;
    //   margin-top: 41px;
    //   img {
    //     vertical-align: middle;
    //   }
    // }
  }
  .joinwe_H5 {
    display: none;
  }

  .foot {
    display: none;
  }
}



@media screen and (max-width: 991px) {
  .home .programa .zhongduanroom .introduce .introduce_item > li[data-v-23543608] {
    width: 47%;
    padding: 0px 5px;
    margin: 30px 0;
    /* -webkit-box-align: center; */
    /* -webkit-align-items: center; */
    /* align-items: center; */
}
.home .programa .zhongduanroom .introduce .introduce_item[data-v-23543608] {
    width: 90%;
    margin: 0% auto;
    display: flex;
    margin-top: 10px;
}
.home .programa .zhongduanroom .introduce .introduce_item > li > p[data-v-23543608] {
     flex: 0.5;
    height: 100%; 
     width: 100%; 
}
.home .programa .zhongduanroom .introduce .introduce_item > li .introduce_item_text > p[data-v-23543608]:nth-of-type(1){
  letter-spacing: 0px;
}
.home .programa .zhongduanroom .introduce .introduce_item > li .introduce_item_text > p[data-v-23543608]:nth-of-type(2) {
    height: auto;
    line-height: 130%;
    font-size: 16px;
    font-weight: 400;
    color: #666666a6;
    letter-spacing: 0px;
}
.home .programa .zhongduanroom .introduce .introduce_item > li > p[data-v-23543608][data-v-23543608] {
  margin-right: 8px;
}
  .home {
    width: 100%;
    .datu {
      width: 100%;
      .bg_img {
        background: url("../assets/img/home_bg75.jpg") no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        .img_text {
          > p:nth-of-type(1) {
            text-align: center;
            color: #ffffff;
            font-size: 60px;
            letter-spacing: 5px;
          }
          > p:nth-of-type(2) {
            text-align: center;
            color: #ffffff;
            font-size: 32px;
            // margin-top: 5px;
            padding: 0px;
            margin: 0px;
          }
        }
        .datu_items {
          width: 98%;
          margin-top: 150px;
          > ul {
            background: rgba(245, 241, 241, 0.001);
            display: flex;
            padding: 20px 10px;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            > li {
              padding: 20px;
              background-color: #ffffff;
              margin: 10px 0px 10px 0px;
              display: flex;
              align-items: center;
              width: 28%;
              flex: auto;
              .tupian {
                flex: 1;
                margin-right: 5px;
                height: 100%;
                > img {
                  width: 100%;
                }
              }
              .tongji {
                flex: 0 0 80%;
                padding-left: 10px;
                p:nth-of-type(1) {
                  font-size: 36px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #000000;
                  letter-spacing: 0.5;
                }
                p:nth-of-type(2) {
                  font-size: 24px;
                  font-weight: normal;
                  color: #000000;
                  letter-spacing: 0;
                }
              }
            }
          }
        }
      }
    }

    .programa {
      width: 100%;
      margin-top: 76px;
      h1 {
        width: 100%;
        font-size: 36px;
        text-align: center;
        font-weight: 550;
        color: #000000;
      }
      .zhongduanroom {
        width: 100%;
        margin: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 36px;
        .recycling_bins {
          width: 100%;
          margin: 0px;
          margin-bottom: 10px;
          flex: 1;
          // display: table-cell;
          // vertical-align: middle;
          //   height: 624px;
          img {
            margin: 0px;
            padding: 0px;
            width: auto;
            max-width: 100%;
            margin: auto;
          }
        }
        .introduce {
          width: 100%;
          // height: auto;
          // background-color: #d8d8d8;
          margin: 0px;
          .introduce_item {
            width: 96%;
            margin: 0% auto;
            // height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: center;
          }
          .introduce_item > li {
            width: 50%;
            // display: flex;
            padding: 15px 0px;
            margin: 20px 0px;
            align-items: center;
            > p {
              flex: 1;
              height: auto;
              width: auto;
              // line-height: 150%;
              img {
                max-width: 100%;
                max-height: 100%;
                margin: auto;
              }
            }
            .introduce_item_text {
              margin-left: 5px;
              flex: 1 1 50%;
              > p:nth-of-type(1) {
                // height: 24px;

                font-size: 24px;
                font-weight: 600;
                height: auto;
                color: #333333;
                margin-bottom: 5px;
                letter-spacing: 3px;
              }
              > p:nth-of-type(2) {
                height: auto;
                line-height: 150%;
                font-size: 12px;
                font-weight: 400;
                color: #666666a6;
                letter-spacing: 2px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    .programa2 {
      background: url(../assets/img/jx.png) no-repeat;
      width: 100%;
      height: auto;
      margin-top: 30px;
      padding: 30px 0px 0px 0px;
      // padding: 80px 0px;
      h1 {
        width: 100%;
        font-size: 32px;
        text-align: center;
        font-weight: 550;
        color: #000000;
      }
      .programa2_text {
        width: 95%;
        margin-top: 25px;
        height: auto;
        font-size: 24px;
        font-weight: normal;
        color: #989898;
        line-height: 150%;
        text-align: left;
      }
      .programa2_items {
        width: 100%;
        height: auto;
        padding: 30px 0px 30px 0px;
        background: #ffffff;
        box-shadow: 0px 0px 18px 2px rgb(203 230 239 / 29%);
        display: flex;
        justify-content: space-around;
        align-items: center;
        > div > p:nth-of-type(1) {
          font-size: 24px;
          // font-weight: 500;
          font-weight: bold;
          color: #000000;
        }
        > div > p:nth-of-type(2) {
          color: #666666a6;
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
    .programa3 {
      margin-top: 76px;
      h1 {
        width: 100%;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: #000000;
      }
      .programa3_liucheng {
        width: 90%;
        margin: 0% auto;
        margin-top: 44px;
        display: flex;
        align-items: center;
        > p {
          flex: 1;
          text-align: center;
          font-weight: 500;
          color: #000000;
          font-size: 28px;
        }
        > img {
          width: 50px;
          flex: 1;
        }
      }
      .programa3_items {
        width: 100%;
        gap:16px;
        margin: 0% auto;

        margin-top: 24px;
        padding-bottom: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        > div {
          width: 40%;
          padding: 0;
          // height: 380px;
          background: #ffffff;
          border-radius: 4px;
          margin: 0;
          align-items: center;
          > .img_home {
            width: 120px;
            height: 120px;
            margin-top: 79px;
            margin-left: 0px;
            img {
              max-width: 100%;
              max-height: 100%;
              margin: auto;
            }
          }
          > div:nth-of-type(2) {
            margin-left: 0px;
            margin-top: 30px;
            font-weight: 600;
            color: #000000;
            width: 100%;
            height: auto;
            font-size: 28px;
            > p {
              width: 45%;
              height: 6px;
              background-color: #157efb;
              margin-top: -5px;
              display: none;
            }
          }
          > p {
            margin-left: 0px;
            margin-top: 15px;
            padding: 0px 0px;
            width: auto;
            height: auto;
            font-size: 24px;
            line-height: 150%;
            color: #666666a6;
          }
        }
        > div:hover {

box-shadow: 0px 0px 18px 2px rgba(14, 165, 211, 0.05);
          .xhx {
            display: block;
          }
        }
      }
    }
    .programa4 {
      padding: 64px 0px 80px 0px;
      background-color: #f5f5f5;
      h1 {
        width: 100%;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: #000000;
      }
      .programa4_items {
        margin-left: 0px;
        width: 100%;
        margin: 0% auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 56px;
        margin-left: 0px;
        .programa4_items_left {
          flex: 1;
          display: flex;
          border-bottom: 1px solid #979797;
          .sekuai {
            background-color: #238af2;
            width: 14px;
            height: 67px;
            margin-top: 5px;
            margin-right: 20px;
          }
          .programa4_room{
            padding-bottom: 10px;
          }
          .programa4_room > ul {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #535354;
            margin-top: 10%;
            > li {
              padding: 5px 0px;
              list-style: none;
              line-height: 150%;
            }
          }
          .programa4_room > .programa4_items_left_title {
            width: 308px;
            height: 33px;
            font-size: 28px;
            font-weight: 600;
            color: #000000;
            line-height: 33px;
          }
        }
        .programa4_items_right {
          // border: 1px solid;
          margin-top: 20px;
          flex: 1;
          width: 100%;
          height: auto;
          margin-left: 0px;
        }
      }
      .fenye {
        width: 120px;
        margin: 0 auto;
        margin-top: 41px;
      }
    }
    .joinwe_H5 {
      padding: 35px 0px;
      background-color: #485862;
      width: 100%;
      display: flex;
      flex-direction: column;
      .callwe {
        text-align: center;
        color: #ffffff;
        font-size: 26px;
      }
      .tishi {
        text-align: center;
        color: #cfcfcf;
        margin-top: 30px;
        font-size: 14px;
      }
      .dianhua {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        margin-left: 50px;
        margin-top: 20px;
        align-items: center;
        > div {
          margin-left: 15px;
          p:nth-of-type(1) {
            font-size: 36px;
            color: #238af2;
          }
          p:nth-of-type(2) {
            font-size: 14px;
            color: #cfcfcf;
          }
        }
      }
      .dizhi {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        margin-left: 50px;
        margin-top: 20px;
        align-items: center;
        > p {
          margin-left: 15px;
          font-size: 14px;
          color: #cfcfcf;
        }
      }
      .email {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        margin-left: 50px;
        margin-top: 20px;
        align-items: center;
        > p {
          margin-left: 15px;
          font-size: 14px;
          color: #cfcfcf;
        }
      }
      .from_H5 {
        width: 96%;
        // align-items: center;
        margin: 0% auto;
        > div {
          margin: 10px 0px;
          // background: #f9f9fa;
          // background: #485862;
          // linear-gradient(
          //   180deg,
          //   rgba(255, 255, 255, 0.86) 0%,
          //   rgba(0, 0, 0, 0.5) 100%
          // );
        }
        input {
          width: 100%;
          height: 72px;
          font-size: 16px;
          border-radius: 4px;
          text-indent: 24px;
          border: none;
          background-color: #f9f9fa;
          //  outline: #e95050 1px;
        }
        textarea {
          width: 100%;
          height: 158px;
          text-indent: 22px;
          max-width: 100%;
          max-height: 158px;
          resize: none;
          background: #f9f9fa;
          border-radius: 4px;
          border: none;
        }
        .yanzhengma {
          display: flex;
          justify-content: space-between;
          > input {
            width: 60%;
          }
          div {
            width: 38%;
            height: 72px;
            border-radius: 4px;
            border: none;
            // background: rgba(18, 28, 36, 0.52);
            img {
              max-width: 100%;
              max-height: 100%;
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }
          }
        }
      }
    }
    .foot {
      margin-top: 42px;
      padding-bottom: 48px;
      ul {
        font-size: 20px;
        width: 90%;
        margin: 0% auto;
        padding: 34px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-bottom: 1px solid #cecbcb;
      }
      div {
        p {
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
  // .bottom {
  //   // display: none;
  // }
}
</style>