import { render, staticRenderFns } from "./Joinwe.vue?vue&type=template&id=64ac93aa&scoped=true&"
import script from "./Joinwe.vue?vue&type=script&lang=js&"
export * from "./Joinwe.vue?vue&type=script&lang=js&"
import style0 from "./Joinwe.vue?vue&type=style&index=0&id=64ac93aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ac93aa",
  null
  
)

export default component.exports