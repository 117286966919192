<template>
  <div>
      <!-- //地图的容器 -->
    <div id="container" style="width:814px;height:379px;"></div>
    <!-- <iframe id="geoPage" width="100%" height="100%" frameborder=0 scrolling="no"
src="http://apis.map.qq.com/tools/poimarker?type=1&keyword=酒店&center=39.908491,116.374328&radius=1000&key=EN4BZ-Y25KJ-PAXFX-FH7JD-2VZIS-YXFWE&referer=myapp">
</iframe> -->
  </div>
</template>

<script>
import maps from "qqmap";
export default {
  data() {
    return {
    //   map: null,
    //   markersArray: [],
    //   polyline: [],
    //   srtart_marker: [],
    //   end_marker: [],
    //   info: [],
    //   listener: [],
    //   marker: [],
      longitude: 0, //经度
      latitude: 0, //纬度
      city: ""
    };
  },
  mounted() {
    // this.baiduMap();
     this.getMyLocation();
  },
  methods: {
    //第一部分
    //定位获得当前位置信息
    getMyLocation() {
      var geolocation = new qq.maps.Geolocation("EN4BZ-Y25KJ-PAXFX-FH7JD-2VZIS-YXFWE", "钱海屯");
      geolocation.getIpLocation(this.showPosition, this.showErr);
      // getLocation.getLocation(this.showPosition, this.showErr)
      //geolocation.getLocation(this.showPosition, this.showErr);//或者用getLocation精确度比较高
    },
    showPosition(position) {
      console.log(position);
      this.latitude = position.lat;
      this.longitude = position.lng;
      this.city = position.city;
      this.setMap();
    },
    showErr() {
      console.log(this.latitude);
      console.log(this.longitude)
      console.log("定位失败");
      this.getMyLocation(); //定位失败再请求定位，测试使用
    },
    //第二部分
    //位置信息在地图上展示
    setMap() {
      //步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      //设置地图中心点
      // var myLatlng = new qq.maps.LatLng(this.latitude, this.longitude);22.528988,113.900369
      var myLatlng = new qq.maps.LatLng(22.533548,113.894058);
      //定义工厂模式函数
      var myOptions = {
        zoom: 13, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
      };
      // //获取dom元素添加地图信息
      var map = new qq.maps.Map(
        document.getElementById("container"),
        myOptions
      );
      //第三部分
      //给定位的位置添加图片标注
      var marker = new qq.maps.Marker({
        position: myLatlng,
        map: map
      });
      //给定位的位置添加文本标注
      var marker = new qq.maps.Label({
        position: myLatlng,
        map: map,
        content: "深圳市钱海屯物联网科技有限公司"
      });
    }

    // baiduMap() {
    //   let map = new BMap.Map("allmap"); // 创建地图实例

    //   let point = new BMap.Point(114.171711, 22.306414); // 创建点坐标
    //   map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别
    //   map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    //   map.addControl(new BMap.NavigationControl()); //添加缩放平移控件
    //   map.addControl(new BMap.ScaleControl()); //添加比例尺控件
    //   map.addControl(new BMap.OverviewMapControl()); //添加缩略图控件
    //   map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
    //   //map.setMapStyle({ style: 'midnight' }) //地图风格

    //   //设置标注的图标
    //   let icon = new BMap.Icon("./static/img/map.png", new BMap.Size(100, 100));
    //   //设置标注的经纬度
    //   let marker = new BMap.Marker(point); // 创建标注
    //   map.addOverlay(marker); // 将标注添加到地图中
    //   let content = "<table>";
    //   content = content + "<tr><td> 公司名称：XXX</td></tr>";
    //   content = content + "<tr><td> 公司地址: XXX</td></tr>";
    //   content = content + "<tr><td> 公司电话: XXX</td></tr>";
    //   content += "</table>";
    //   //提示信息
    //   let infoWindow = new BMap.InfoWindow(content);
    //   // 鼠标移上标注点要发生的事
    //   marker.addEventListener("mouseover", function() {
    //     this.openInfoWindow(infoWindow);
    //   });

    //   // 鼠标移开标注点要发生的事
    //   marker.addEventListener("mouseout", function() {
    //     //this.closeInfoWindow(infoWindow)
    //   });

    //   //   个别功能按需导入即可

    //   // //   点击地图，获取经纬度坐标
    //   //   map.addEventListener("click", function(e) {
    //   //     document.getElementById("allmap").innerHTML =
    //   //       "经度坐标：" + e.point.lng + " &nbsp;纬度坐标：" + e.point.lat;
    //   //   });

    //   // // 获取当前坐标位置
    //   // let geolocation = new BMap.Geolocation();
    //   // geolocation.getCurrentPosition(function(r) {
    //   //   if (this.getStatus() == BMAP_STATUS_SUCCESS) {
    //   //     var mk = new BMap.Marker(r.point);
    //   //     map.addOverlay(mk);
    //   //     map.panTo(r.point);
    //   //     // alert("您的位置：" + r.point.lng + "," + r.point.lat);
    //   //   } else {
    //   //     // alert("failed" + this.getStatus());
    //   //   }
    //   // });
    // }
  }
};
</script>

<style lang="scss" scoped>
// .baidumap {
//   width: 1000px;
//   height: 500px;
//   border: 1px solid red;
//   position: absolute;
//   left: 0;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
// }

/* 去除百度地图版权那行字 和 百度logo */
// .baidumap > .BMap_cpyCtrl {
//   display: none !important;
// }
// .baidumap > .anchorBL {
//   display: none !important;
// }
</style>

