<template>
  <div class="home">
    <keep-alive>
      <!-- <Home></Home> -->
    </keep-alive>
    <div class="head_img">
      <h1>加入我们</h1>
      <p>每一次投递都资源可回收， 加入钱海屯，一起为环保助力</p>
    </div>
    <div class="coneirOne">
      <div>
        <div class="left_img"></div>
        <div class="right_text">
          <div class="title">
            <p>为什么选择钱海屯</p>
            <p class="yanse"></p>
          </div>
          <div class="article">
            <p>全链路专车转运智能回收；</p>
            <p>
              每一次投递都可是为环保做贡献，从社区源头做起，培养社群用户做随手可行的环保人；
            </p>
            <p>
              社区安装智能柜—>智能回收柜—>分拣中心—>工厂再生加工—>商品出售—>社群使用
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="coneirTwo">
      <div>
        <div class="left_text">
          <div class="title">
            <p>加入代理的收益</p>
            <p class="yanse"></p>
          </div>
          <div class="article">
            <p>用户：微信小程序有偿回收，垃圾分类返积分，积分兑换或提现；</p><br>
            <p>政府相关机构：减轻城市垃圾分类压力；</p></br>
            <p>物业：美化小区合理垃圾分类，数字化管理享收益。</p></br>
            <p>管理者：Web后台，数字化运营；</p></br>
            <p>管理者可使用微信实时查看箱体数据和状态；</p>
            <p>-垃圾箱状态实时查看</p>
            <p>-实时垃圾容量和投递记录，降低管理成本；</p>
            <p>-大数据分析投递数据，确保回收率和位置合理性；</p>
          </div>
        </div>
        <div class="right_img"></div>
      </div>
    </div>
    <div class="coneirThree">
      <div>
        <div class="title">
          <p>合作加盟</p>
          <p class="yanse"></p>
        </div>
        <p>如果您有建议或任何疑问，请联系我们</p>
        <div class="from">
          <div class="from_left">
            <p>
              <input
                type="text"
                name
                placeholder="姓名"
                id
                v-model="name"
                required
              />
            </p>
            <p>
              <input
                type=""
                name
                placeholder="电话"
                id
                v-model="dianhua"
                required
              />
            </p>
            <p>
              <input
                type="text"
                name
                placeholder="公司"
                id
                v-model="gongsi"
                required
              />
            </p>
          </div>
          <div class="from_right">
            <div>
              <textarea
                rows
                cols
                value
                placeholder="请输入留言内容..."
                v-model="liuyan"
              >
              </textarea>
              <!-- <input type="text" name placeholder="请输入留言内容..." id v-model="liuyan" /> -->
            </div>
            <p>
              <input
                type="text"
                name
                placeholder="验证码"
                id
                v-model="yzm"
                required
              />
              <span class="img" @click="yanzhengma">
                <img :src="img" />
              </span>
            </p>
          </div>
        </div>
        <div class="tijiao" @click="tijiao">提交</div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Home from "../views/Home";
import Bottom from "../components/Bottom";
import { Message } from "element-ui";
export default {
  name: "Joinwe",
  components: {
    Home,
    Bottom,
  },
  data() {
    return {
      name: "",
      dianhua: "",
      gongsi: "",
      liuyan: "",
      yzm: "",
      img: "",
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScrollx, true);
    // ----------获取验证码----------
    // this.yanzhengma();
      this.$axios({
      methods: "GET",
      url: "/contactus/getcode",
    })
      .then((result) => {
        // console.log(result.data);
        this.img = "data:image/png;base64," + result.data.data;
      })
      .catch((err) => {
        // console.log(err);
      });
    
  },
  methods: {
    
//     function debounce(fn, interval = 300) {
//     let timeout = null;
//     return function () {
//         clearTimeout(timeout);
//         timeout = setTimeout(() => {
//             fn.apply(this, arguments);
//         }, interval);
//     };
// }
 // --------------提交------------------
    tijiao() {
      console.log(this.dianhua)
      var mPattern = /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/;
      var regexp=/^(\d{2,4}-?)?\d{7,8}$/;
      let type='';
      let message='';     
      if (this.name == "" || this.dianhua == "" || this.gongsi == "") {
        type='warning';
        message="请将信息填写完整！"
        // Message({
        //   // duration:500,
        //   type:warning,
        //   showClose: true,
        //   message: message,
        // });
      } 
      else if (this.yzm == "") {
        type='warning';
        message="请输入验证码！"
        // Message.warning({
        //   showClose: true,
        //   message: "请输入验证码！",
        // });
      } 
      else if (mPattern.test(this.dianhua) == false && regexp.test(this.dianhua)==false) {
        type='warning';
        message="请输入正确的电话号码！"
          //  Message.error('请输入正确的电话号码！');
      }
      else {
        this.$axios({
          method: "post",
          url: "/contactus/insertContactus",
          data: {
            name: this.name,
            phone: this.dianhua,
            company: this.gongsi,
            content: this.liuyan,
            code: this.yzm,
          },
        })
          .then((result) => {
            console.log(result.data);
            if (result.data.code == 200) {
              type='success';
              message="提交成功！";
              this.yanzhengma()
              // Message.success({
              //   showClose: true,
              //   message: "提交成功！",
              //   onClose:()=>{
              //     this.yanzhengma()
              //   }
              // })
            }else if(result.data.code==500){
              type='error';
              message=result.data.data;
              this.yanzhengma()
              // Message.error({
              //   message:result.data.data,
              //   onClose:()=>{
              //      this.yanzhengma()
              //   }
              // }) 
            }
            
          })
          .catch((err) => {

          });
      }
      Message ({
          // duration:500,
          type:type,
          showClose: true,
          message: message
      });
    },

    // 点击验证码刷新验证码
    yanzhengma() {
      this.$axios({
        methods: "GET",
        url: "/contactus/getcode",
      })
        .then((result) => {
          // console.log(result.data);
          this.img = "data:image/png;base64," + result.data.data;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-input-placeholder { /* Chrome/Opera/Safari */ 
			color: rgb(211, 209, 209);
		}
		::-moz-placeholder { /* Firefox 19+ */  
			color: rgb(219, 217, 217);
		}
		:-ms-input-placeholder { /* IE 10+ */ 
		 color: rgb(219, 217, 217);
		}
		:-moz-placeholder { /* Firefox 18- */ 
		 color: rgb(219, 217, 217);
		}

.home {
  width: 100%;
  .head_img {
    height: 560px;
    width: 100%;
    background: url("../assets/img/bj22.png") no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      margin: 0px 0px 0px 280px;
      padding-top: 222px;
      // width: 296px;
      height: 70px;
      font-size: 68px;
      font-weight: 600;
      color: #ffffff;
      line-height: 70px;
      letter-spacing: 5px;
    }
    p {
      // width: 364px;
      height: 72px;
      font-size: 24px;
      font-weight: 400;
      color: #eeeeee;
      line-height: 36px;
      margin: 15px 0px 0px 280px;
    }
  }
  .coneirOne {
    // width: 1920px;
    width: 100%;
    height: 500px;
    background: #ffffff;
    > div {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      .left_img {
        flex: 1 1 812px;
        background: url("../assets/img/quanlianlu.png") no-repeat;
        height: 394px;
        background-size: contain;
        background-position: center;
      }
      .right_text {
        flex: 0 0 600px;
        margin-top: 129px;
        margin-left: 145px;
        .title {
          > p:nth-of-type(1) {
            // width: 256px;
            height: 45px;
            font-size: 32px;
            font-weight: 600;
            color: #000000;
            line-height: 45px;
          }
          .yanse {
            width: 288px;
            height: 21px;
            background: linear-gradient(90deg, #48bffa 0%, #238af2 100%);
            border-radius: 46px;
            opacity: 0.14;
            margin-top: -22px;
            margin-left: -30px;
          }
        }
        .article {
          margin-top: 18px;
          // width: 453px;
          height: 154px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          letter-spacing: 4px;
          p:nth-of-type(3) {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .coneirTwo {
    //   width: 1920px;
    width: 100%;
    height: 500px;
    background: #f9f9fa;
    > div {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      .left_text {
        flex: 0 0 600px;
        margin-top: 74px;
        // margin-left: 145px;
        .title {
          > p:nth-of-type(1) {
            width: 256px;
            height: 45px;
            font-size: 32px;
            font-weight: 600;
            color: #000000;
            line-height: 45px;
          }
          .yanse {
            width: 288px;
            height: 21px;
            background: linear-gradient(90deg, #48bffa 0%, #238af2 100%);
            border-radius: 46px;
            opacity: 0.14;
            margin-top: -22px;
            margin-left: -30px;
          }
        }
        .article {
          margin-top: 18px;
          width: 453px;
          height: 154px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          letter-spacing: 4px;
          //   p:nth-of-type(3) {
          //     margin-top: 10px;
          //   }
        }
      }
      .right_img {
        flex: 1 1 812px;
        background: url("../assets/img/bianzu.png") no-repeat;
        height: 394px;
        background-size: contain;
        background-position: center;
        margin-left: 54px;
        margin-top: 44px;
      }
    }
  }
  .coneirThree {
    width: 100%;
    padding: 79px 0px;
    > div {
      width: 1440px;
      margin: 0 auto;
      .title {
        > p:nth-of-type(1) {
          width: 256px;
          height: 45px;
          font-size: 32px;
          font-weight: 600;
          color: #000000;
          line-height: 45px;
        }
        .yanse {
          width: 288px;
          height: 21px;
          background: linear-gradient(90deg, #48bffa 0%, #238af2 100%);
          border-radius: 46px;
          opacity: 0.14;
          margin-top: -22px;
          margin-left: -30px;
        }
      }
      > p {
        font-size: 14px;
        letter-spacing: 4px;
        margin-top: 18px;
        color: #535354;
      }
      .from {
        margin-top: 4px;
        display: flex;
        .from_left {
          p {
            width: 332px;
            height: 48px;
            margin-top: 14px;
            input {
              width: 100%;
              height: 100%;
              outline: #e95050 1px;
              text-indent: 24px;
              border: none;
              background-color: #f9f9fa;
              border-radius: 4px;
              //   font-size: 14px;
              // color: #cfcfcf;
            }
          }
        }
        .from_right {
          margin-left: 31px;
          > div {
            width: 570px;
            height: 110px;
            margin-top: 14px;
            textarea {
              width: 100%;
              height: 100%;
              text-indent: 22px;
              outline: #e95050 1px;
              padding: 8px 5px 0px 5px;
              max-width: 570px;
              max-height: 110px;
              resize: none;
              background: #f9f9fa;
              border-radius: 4px;
              border: none;
              // color: #cfcfcf;
            }
            // input {
            //   width: 100%;
            //   height: 100%;
            //   text-indent: 22px;
            //   outline: none;
            //   border: none;
            // }
          }
          > p {
            margin-top: 14px;
            width: 570px;
            height: 45px;
            display: flex; // margin-top: 14px;
            > input:nth-of-type(1) {
              width: 150px;
              height: 100%;
              text-indent: 24px;
              border-radius: 4px;
              outline: #e95050;
              border: none;
              background-color: #f9f9fa;
            }
            > .img {
              width: 150px;
              height: 45px;
              margin-left: 14px;
              outline: none;
              background-color: #121c24;
              border-radius: 4px;
              img {
                max-width: 100%;
                max-height: 100%;
                height: 100%;
                display: block;
                margin: auto;
                opacity: 0.96;
              }
            }
          }
        }
      }
      .tijiao {
        width: 148px;
        height: 48px;
        font-size: 24px;
        line-height: 48px;
        background: #238af2;
        border-radius: 4px;
        text-align: center;
        color: #ffffff;
        margin-top: 14px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .home {
    width: 100%;
    .head_img {
      width: 100%;
      height: 280px;
      background: url('../assets/img/bj22_yd.png') no-repeat;
      background-size: cover;
      background-position: center;
      padding: 0%;
      padding-top: 50px;
      h1 {
        margin: 0% auto;
        padding-top: 8%;
        font-size: 40px;
        font-weight: 600;
        color: #ffffff;
        line-height: 70px;
        letter-spacing: 5px;
        margin-left: 40px;
      }
      p {
        padding: 0% 0%;
        width: 45%;
        height: auto;
        font-size: 20px;
        font-weight: 400;
        color: #eeeeee;
        line-height: 150%;
        margin: 0% 0% 0% 5%;
        letter-spacing: 2px;
      }
    }
    .coneirOne {
      width: 100%;
      height: auto;
      background: #ffffff;
      > div {
        width: 90%;
        margin: 0% auto;
        flex-direction: column;
        .left_img {
          flex: none;
          background: url("../assets/img/quanlianlu.png") no-repeat;
          height: 334px;
          background-size: contain;
          background-position: center;
        }
        .right_text {
          flex: 1;
          margin: 0% auto;
          margin-top: 40px;
          .title {
            > p:nth-of-type(1) {
              // width: 256px;
              height: auto;
              font-size: 32px;
              font-weight: 600;
              color: #000000;
              line-height: 150%;
            }
            .yanse {
              width: 288px;
              height: 21px;
              background: linear-gradient(90deg, #48bffa 0%, #238af2 100%);
              border-radius: 46px;
              opacity: 0.14;
              margin-top: -22px;
              margin-left: -30px;
            }
          }
          .article {
            margin-top: 18px;
            width: 100%;
            height: 100%;
            font-size: 26px;
            font-weight: 400;
            color: #666666;
            line-height: 150%;
            letter-spacing: 4px;
            p:nth-of-type(3) {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .coneirTwo {
      width: 100%;
      height: 100%;
      background: #f9f9fa;
      opacity: 0.85;
      > div {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .left_text {
          flex: 1;
          width: 100%;
          margin-top: 50px;
          .title {
            > p:nth-of-type(1) {
              width: 256px;
              height: 45px;
              font-size: 32px;
              font-weight: 600;
              color: #000000;
              line-height: 45px;
            }
            .yanse {
              width: 288px;
              height: 21px;
              background: linear-gradient(90deg, #48bffa 0%, #238af2 100%);
              border-radius: 46px;
              opacity: 0.14;
              margin-top: -22px;
              margin-left: -30px;
            }
          }
          .article {
            margin-top: 18px;
            width: 100%;
            font-size: 26px;
            height: 100%;
            font-weight: 400;
            color: #666666;
            line-height: 150%;
            letter-spacing: 4px;
          }
        }
        .right_img {
          flex: none;
          width: 90%;
          margin: 0 auto;
          background: url("../assets/img/bianzu.png") no-repeat;
          background-size: 100% auto;
          max-width: 100%;
          max-height: 100%;
          margin-top: 30px;
          border: none;
        }
      }
    }
    .coneirThree {
      width: 100%;
      padding: 79px 0px;
      display: flex;
      flex-direction: column;
      > div {
        width: 90%;
        margin: 0 auto;
        .title {
          > p:nth-of-type(1) {
            width: 100%;
            font-size: 32px;
            font-weight: 600;
            color: #000000;
            line-height: 45px;
          }
          .yanse {
            width: 288px;
            height: 21px;
            background: linear-gradient(90deg, #48bffa 0%, #238af2 100%);
            border-radius: 46px;
            opacity: 0.14;
            margin-top: -22px;
            margin-left: -30px;
          }
        }
        > p {
          font-size: 24px;
          letter-spacing: 4px;
          margin-top: 18px;
          color: #535354;
        }
        .from {
          margin-top: 4px;
          display: flex;
          flex-direction: column;
          .from_left {
            p {
              width: 400px;
              height: 60px;
              margin-top: 30px;
              input {
                width: 100%;
                height: 100%;
                outline: #e95050 1px;
                text-indent: 24px;
                border: none;
                background-color: #f9f9fa;
                border-radius: 4px;
                padding: 5px;
                font-size: 28px;
                //   font-size: 14px;
                // color: #cfcfcf;
              }
            }
          }
          .from_right {
            margin-left: 0px;
            > div {
              width: 100%;
              height: 110px;
              margin-top: 30px;
              textarea {
                width: 100%;
                height: 100%;
                text-indent: 22px;
                outline: #e95050 1px;
                padding: 8px 5px 0px 5px;
                max-width: 100%;
                max-height: 110px;
                resize: none;
                background: #f9f9fa;
                border-radius: 4px;
                border: none;
                font-size: 24px;
              }
            }
            > p {
              margin-top: 30px;
              width: 100%;
              height: 60px;
              display: flex; // margin-top: 14px;
              align-items: center;
              > input:nth-of-type(1) {
                width: 30%;
                height: 100%;
                text-indent: 24px;
                border-radius: 4px;
                outline: #e95050;
                font-size: 28px;
                border: none;
                background-color: #f9f9fa;
              }
              > .img {
                display: block;
                width: 25%;
                height: 48px;
                margin-left: 14px;
                outline: none;
                background-color: #121c24;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  display: block;
                  margin: auto;
                  opacity: 0.5;
                }
              }
            }
          }
        }
        .tijiao {
          width: 148px;
          height: 60px;
          line-height: 60px;
          background: #238af2;
          border-radius: 4px;
          text-align: center;
          color: #ffffff;
          margin-top: 14px;
        }
      }
    }
  }
}
</style>