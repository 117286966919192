<template>
  <div class="home" @click="navShow=false">
		<div class="mask" @click="mask=false" v-if="mask"><img :src="require('../assets/img/jiao.svg')" /><p>我知道啦</p></div>
		<div class="maskNav" @click="maskNav=false" v-if="maskNav"></div>
    <div class="head_nav" :style="{ backgroundColor: styleObject,  backdropFilter: backdropFilter }">
      <div>
        <div class="head">
          <img :src="logo" class="logo" />
          <img class="navbar" @click.stop="showOrCloseNav()" :src="navImg" />
        </div>
        <div class="daohang" :class="navShow ? 'activenav' : ''">
          <ul>
            <li
              v-for="(item, index) in navItems"
              :key="index"
              @click.stop="daohang(index)"
              :class="[index == num ? 'dhitms' : (num == 4 ? 'color-black' : '')]"
              class="dhli"
              
            >
              <p><img :src="item.icon" v-if="isMobile"/></p>
              <div>{{ item.title }}</div>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <router-view @showMask="showMask">
      <Index></Index>
    </router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import Index from "../views/Index";
// import News from "../views/News";
import Product from "../views/Product";
import Joinwe from "../views/Joinwe";
import Aboutwe from "../views/Aboutwe";
import { Icon } from "element-ui";
// import { mapMutations, mapState } from "vuex";
export default {
  name: "Home",
  components: {
    Index,
    Product,
    Joinwe,
    Aboutwe,
  },
  data() {
    return {
      maskNav: false,
			isMobile: true,
      styleObject: "",
      mask: false,
      navItems: [
        {
          title: '首页',
          icon: require("../assets/img/nav-0.png")
        }, {
          title: '产品介绍',
          icon: require("../assets/img/nav-1.png")
        }, {
          title: '代理加盟',
          icon: require("../assets/img/nav-2.png")
        }, {
          title: '关于我们',
          icon: require("../assets/img/nav-3.png")
        }, {
          title: 'App下载',
          icon: require("../assets/img/nav-4.png")
        }
      ],
      dhtiao: true,
      backdropFilter: '',
      num: 0,
      fontcolor: "",
      navShow: false,
      navImg: require("../assets/img/list.png"),
      logo: require("../assets/img/logo-unnormal.svg"),
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
    };
  },
  watch: {
    windowHeight(val) {
      let that = this;
      // console.log("实时屏幕高度：", val, that.windowHeight);
    },
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val, that.windowHeight);
    },
    navShow(val) {
      if(this.isMobile) {
        this.maskNav = val
      }
    }
  },
  created() {
    // if (this.windowWidth <= 991) {
    //   this.logo = require("../assets/img/logo-normal.svg");
    // }
    console.log(this.$route.path);
    switch (this.$route.path) {
      case '/index':
        this.num = 0;
        break;
      case '/product':
        this.num = 1;
        break;
      case '/joinwe':
        this.num = 2;
        break;
      case '/aboutwe':
        this.num = 3;
        break;
      case '/AppDownload':
        this.num = 4;
        break;
    
      default:
        this.num = 0;
        break;
    }
    this.styleObject = "transparent";
    if (this.num == 4) {
      this.logo = require("../assets/img/logo-normal.svg");
    }
    else {
      
        this.navImg = require("../assets/img/nav-white.svg");
    }
    document.title = "钱海屯";
    // console.log(localStorage.getItem("num"));
    // this.num = this.navnum;
  },
  mounted() {
		this.Mobile();
    window.addEventListener("scroll", this.handleScrollx, true);
    let __that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        __that.windowHeight = window.fullHeight; // 高
        __that.windowWidth = window.fullWidth; // 宽
        console.log("实时屏幕宽度：", __that.windowWidth);
      })();
    };
  },
  methods: {
    showMask(val) {
      this.mask = val;
    },
		Mobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			flag ? (this.isMobile = true) : (this.isMobile = false);
		},
    // ...mapMutations(['changeNum']),
    handleScrollx() {
      console.log("滚动高度", window.pageYOffset);
      // console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)
      if (this.windowWidth > 991) {
        if (window.pageYOffset > 105) {
          this.logo = require("../assets/img/logo-normal.svg");
          this.styleObject = "#ffffff"; // 背景色
          for (
            let i = 0;
            i < document.getElementsByClassName("dhli").length;
            i++
          ) {
            // 所有未被选中的变黑色字体
            document.getElementsByClassName("dhli")[i].style.color = "#000000";
          }
        } else {
          this.logo = require("../assets/img/logo-unnormal.svg");
          this.styleObject = "";
          for (
            let i = 0;
            i < document.getElementsByClassName("dhli").length;
            i++
          ) {
            // 变回白色
            document.getElementsByClassName("dhli")[i].style.color = "#ffffff";
          }
          console.log(this.num);
          if (this.num == 4) {
            this.logo = require("../assets/img/logo-normal.svg");
            for (
              let i = 0;
              i < document.getElementsByClassName("dhli").length;
              i++
            ) {
              // 所有未被选中的变黑色字体
              document.getElementsByClassName("dhli")[i].style.color =
                "#000000";
            }
          }
        }
      } else {
        this.backdropFilter = '0'
        this.styleObject = "transparent";
        if (window.pageYOffset > 1) {
          this.backdropFilter = 'blur(10px)';
          this.styleObject = "rgba(255,255,255)";
          this.logo = require("../assets/img/logo-normal.svg");
          if(this.navShow) {
            this.navImg = require("../assets/img/close2.png");
          }
          else {
            this.navImg = require("../assets/img/list.png");
          }
        }
        else {
          if(!this.navShow) {
            this.backdropFilter = '';
            this.styleObject = "transparent";
            console.log(this.backdropFilter);
            if(this.num!==4){
            this.logo = require("../assets/img/logo-unnormal.svg");
            this.navImg = require("../assets/img/nav-white.svg");
            }
          }
          else {
            this.navImg = require("../assets/img/close2.png");
            this.backdropFilter = 'blur(10px)';
            this.styleObject = "rgba(255,255,255)";
          }
        }
      }
    },
    showOrCloseNav() {
      this.navShow = !this.navShow;
      if (this.navShow) {
        if(this.isMobile) {
          this.backdropFilter = 'blur(10px)'
          this.styleObject = "rgba(255,255,255)";
        }
        this.navImg = require("../assets/img/close2.png");
        this.logo = require('../assets/img/logo-normal.svg');
      } else {
        this.backdropFilter = ''
        this.styleObject = "transparent";
        this.navImg = require("../assets/img/list.png");
        if(this.num==0 || this.num==1 || this.num==2) {
          this.logo = require('../assets/img/logo-unnormal.svg');
        }
        if(this.num!==4) {
          this.navImg = require("../assets/img/nav-white.svg");
        }
      }
    },
    daohang(index) {
      // this.changeNum(index);
      this.num = index;
      // localStorage.setItem("num", this.num);
      window.scrollTo(0, 0);
      console.log(this.num);
      if (index == 0) {
        // console.log("首页");
        this.$router.push({
          name: "Index",
        });
      }
      if (index == 1) {
        // console.log("产品介绍");
        this.$router.push({
          name: "Product",
        });
      }
      if (index == 2) {
        // console.log("代理加盟");
        this.$router.push({
          name: "Joinwe",
        });
      }
      if (index == 3) {
        // console.log("关于我们");
        this.$router.push({
          name: "Aboutwe",
        });
      }
      if (index == 4) {
        this.$router.push({
          name: "AppDownload",
        });
        this.logo = require("../assets/img/logo-normal.svg");
        if(this.windowWidth>991){
          for (
            let i = 0;
            i < document.getElementsByClassName("dhli").length;
            i++
          ) {
            // 变回白色
            document.getElementsByClassName("dhli")[i].style.color = "#000";
          }
        }
        else {
          console.log(this.styleObject);
          this.styleObject = "transparent";
        }
      } else {
        this.navImg = require("../assets/img/nav-white.svg");
        if(this.windowWidth>991){
          for (
            let i = 0;
            i < document.getElementsByClassName("dhli").length;
            i++
          ) {
            // 变回白色
            document.getElementsByClassName("dhli")[i].style.color = "#fff";
            this.logo = require("../assets/img/logo-unnormal.svg");
          }
        }
        else {
          this.logo = require("../assets/img/logo-unnormal.svg");
        }
      }
      if(this.isMobile) {
        this.showOrCloseNav()
      }
      // console.log(index, this.num);
    },
  },
};
</script>

<style lang="scss" scoped>
.maskNav {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7000);
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  background: rgba(0,0,0,0.7000);
  img {
    width: 367px;
  }
  p {
    width: 248px;
    height: 80px;
    background: linear-gradient(135deg, #2FB5FD 0%, #157EFB 100%);
    box-shadow: 6px 6px 16px 0px rgba(21,126,251,0.5000);
    border-radius: 60px;
    margin: 31px 77px 0 0;
    line-height: 80px;
    text-align: center;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 32px;
  }
}
.home {
  width: 100%;
}
.dhitms {
  color: #157efb;
  > p {
    background-color: #157efb;
  }
}
.active {
  > p {
    background-color: none;
  }
  color: #ffffff;
}

li {
  list-style: none;
  cursor: pointer;
}
.head_nav {
  .logo {
    width: 163px;
    height: 64px;
    margin: 10px 0;
  }
  .dhitms {
    > div {
      color: #157efb;
    }
  }
  .navbar {
    display: none;
  }
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 6;
  > div {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    ul {
      flex: 0 0 55%;
      display: flex;
      // width: 55%;
      // width: 729px;
      // flex-direction: row;
      justify-content: space-around;
      align-items: center;
      gap: 88px;
      a {
        flex: 1 1 auto;
      }
      li {
        flex: 1 1 auto;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        color: #ffffff;
        // justify-content: start;
        p {
          position: absolute;
          top: 0;
          width: 100%;
          margin: 0 auto;
          height: 6px;
          // background-color: #157efb;
          // opacity: 0;
        }
        > div {
          margin-top: 25px;
          font-size: 17px;
        }
      }
      .color-black {
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .head_nav {
    background-color: #fff;
    height: 84px;
    position: fixed;
    .head {
      padding: 0 15px;
    }
    > div {
      width: 100%;
      margin: 0;
      display: block;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        padding: 16px;
        li {
          background: rgba(255,255,255);
          border-radius: 16px;
          border: 1px solid #F1F1F1;
          width: 29%;
          height: 200px !important;
          flex: auto !important;
          position: relative;
          div {
            color: #191C27;
            line-height: 40px;
            font-size: 32px;
            margin: 32px 0 0 32px;
            text-align: left;
          }
          p {
            width: 120px;
            height: 120px;
            position: absolute;
            right: 16px;
            bottom: 16px;
            top: auto;
            img {
              width: 120px;
              height: 120px;
            }
          }
        }
        li:last-child {
          background: transparent;
          border: none;
        }
      }
      > div {
        flex: 1;
      }
      > ul {
        display: flex;
        // width: 55%;
        // width: 729px;
        // flex-direction: row;
        color: #191C27;
        flex-wrap: wrap;
        padding-left: 0px;
        li {
        }
      }
    }
    .dhitms {
      color: #157efb;
      border: 1px solid #157efb;
      > div {
        position: absolute;
      }
      > p {
        background-color: #fff;
      }
    }
    .navbar {
      display: block;
      width: 44px;
      height: 44px;
    }
  }
  .logo {
    width: 163px;
    height: 64px;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar {
      margin-right: 40px;
    }
  }
  .daohang {
    position: absolute;
    top: 84px;
    background-color: #fff;
    left: 0px;
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
  }
  .activenav {
    height: auto;
    padding: 10px 0 20px;
    opacity: 1;
    // box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    background: rgba(255,255,255);
    backdrop-filter: blur(10px);
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px;
      li {
        background: rgba(255,255,255);
        border-radius: 16px;
        border: 1px solid #F1F1F1;
        width: 29%;
        height: 200px !important;
        flex: auto !important;
        position: relative;
        div {
          color: #191C27;
          line-height: 40px;
          font-size: 32px;
          margin: 32px 0 0 32px;
          text-align: left;
        }
        p {
          width: 120px;
          height: 120px;
          position: absolute;
          right: 16px;
          bottom: 16px;
        }
      }
      li:last-child {
        background: transparent;
        border: none;
      }
    }
  }
  .active {
    > p {
      background-color: none;
    }
    color: #000000;
  }
}
</style>
